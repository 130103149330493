import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import StyledButton from "./styledButton";

import BackgroundImage from "gatsby-background-image";

import analytics from "../analytics";

const Background = ({ className }, props) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "cover1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    //* Fehlermeldunng es so zu implementieren...*//
    // doTrack = () => {
    //   analytics.track('buttonClicked', {
    //     foo: 'bar'
    //   })
    // }

    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;

      return (
        <BackgroundImage
          Tag="section"
          className="w-full h-screen bg-no-repeat bg-cover bg-white"
          fluid={imageData}
        >
          <div className="w-full flex flex-col pt-12 md:py-64 md:pl-16 h-full lg:w-3/4 xl:w-1/2">
            <div className="h-1/2 bg-white bg-opacity-75 rounded-lg flex flex-col md:flex-row text-xl p-4 leading-wide tracking-wide justify-center items-center content-center">
              <div className="flex-col h-full w-full justify-center items-center text-center">
                <p>
                  <a
                    href="tel:0562210023"
                    className="md:text-3xl text-brand hover:text-brand3 font-semibold text-center"
                  >
                    056 221 00 23
                  </a>
                </p>
                <p>
                  <a
                    href="mailto:info@praxis-am-laegern.ch"
                    className="md:text-3xl text-brand hover:text-brand3 font-semibold"
                  >
                    info@praxis-am-laegern.ch
                  </a>
                </p>
                <p className="text-xl md:text-3xl text-brand3 hover:text-brand">
                  <Link to="/kontakt">Kirchweg 20, 5420 Ehrendingen</Link>
                </p>
              </div>
              <div className="flex-initial md:mr-4 mt-4 mb-1 md:mt-0 md:mb-0">
                <Link to="/kontakt">
                  <StyledButton>Termin vereinbaren</StyledButton>
                </Link>
              </div>
            </div>
            <div className="bg-white bg-opacity-75 rounded-lg p-6 mt-10">
              <h1 className="text-xl md:text-4xl text-brand leading-tight font-medium">
                Ihr SSO Zahnarzt für
                <br /> Ehrendingen und Umgebung.
              </h1>
              <p className="text-base tracking-wide font-normal mt-4">
                Die Praxis ist modern eingerichtet und auf dem neusten Stand der
                Technik gehalten. Unsere Partner und Lieferanten garantieren die
                Einhaltung unserer hohen Qualitätsanforderungen an Material und
                Dienstleistungen.
              </p>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

export default Background;
