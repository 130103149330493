import Analytics from "analytics";
import gtagManagerPlugin from "@analytics/google-tag-manager";
// ... whatever analytics provider you use

const analytics = Analytics({
  plugins: [
    gtagManagerPlugin({
      containerId: "GTM-TM5VGVJ",
    }),
  ],
});

// Set to global so analytics plugin will work with Gatsby
if (typeof window !== "undefined") {
  window.Analytics = analytics;
}

/* export for consumption in your components for .track & .identify calls */
export default analytics;
