import React from "react";
import cx from "classnames";
import guenstig from "../images/guenstig.svg";
import lokal from "../images/lokal.svg";
import innovativ from "../images/innovativ.svg";
// import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4 bg-brand hover:bg-brand3 text-base text-white font-bold uppercase rounded shadow-md",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

const Service = ({ title, url, children }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-6 my-2">
      <Link
        to={url}
        className="text-2xl text-brand hover:text-brand3 hover:underline"
      >
        {title}
      </Link>
      <p>{children}</p>
    </div>
  );
};

function Index({ data }) {
  const services = data.services.edges;

  return (
    <Layout data={data} headerClass="relative bg-white z-40" bodyClass="">
      <SEO title="Home" />

      <div className="relative min-h-screen items-center mb-4 z-30 ">
        <div className="flex flex-col mt-10 md:mt-16">
          <p className="mb-4 text-4xl text-brand3 self-center">Unser Angebot</p>

          <div className="flex flex-wrap">
            {services.map(({ node }) => (
              <Service
                title={node.frontmatter.title}
                url={node.frontmatter.path}
                key={node.frontmatter.path}
              >
                {node.excerpt}
              </Service>
            ))}
          </div>

          <div className="self-center mt-8">
            <Link to="/angebot">
              <StyledButton>Das ganze Angebot</StyledButton>
            </Link>
          </div>
        </div>

        <div className="mt-16">
          <Img fluid={data.praxisImg.childImageSharp.fluid} />
        </div>

        <div className="flex flex-col mt-10 md:mt-16 mb-16">
          <p className="mb-2 text-4xl text-brand3 self-center">Ihre Vorteile</p>

          <div className="flex flex-wrap justify-center items-stretch -mx-2">
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 rounded-lg border border-brand2 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="guenstig" src={guenstig} />
                </div>
                <p className="text-2xl w-full">preiswert</p>
                <p>
                  Wir bieten unsere Leistungen zu den günstigsten Konditionen
                  an.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 rounded-lg border border-brand2 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="lokal" src={lokal} />
                </div>
                <p className="text-2xl w-full">lokal</p>
                <p>
                  Wir kennen unsere Kunden,
                  <br />
                  unsere Kunden kennen uns.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 rounded-lg border border-brand2 flex flex-col items-center text-center">
                <div className="w-16 h-16 mb-4">
                  <img alt="innovativ" src={innovativ} />
                </div>
                <p className="text-2xl w-full">innovativ</p>
                <p>
                  Wir bleiben stets auf den neusten Stand der
                  Behandlungsmethoden.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          frontmatter {
            title
            path
          }
          excerpt
        }
      }
    }
    coverImg: file(relativePath: { eq: "cover1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    praxisImg: file(relativePath: { eq: "ops1_large.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Index;
